#dialog-info-notification{
    
    .modal-header.header-floating{
        position: absolute;
        width: 100%;
        border-bottom: none;
        background-color: rgba(255, 255, 255, 0.7);
        border-radius: 0px;

        button.close{
            font-size: 40pt;
            padding: 0px 1rem;
        }
    }

    .modal-content{
        border-radius: 0px !important;
        border: none;

        h4{
            font-size: 18pt;
        }

        th, td{
            font-size: 12pt;
        }

    }
}