.custom-nav-link{
    // color: white !important;
    padding: 5px 8px;
    border-radius: 5px;
    // &:hover{
    //     transition: 0.3s ease;
    //     background-color: white;
    //     color: $primary !important;
    // }
}

.custom-nav-link.active{
    background-color: orange;
    color: white !important;
    font-weight: bold;

}

.object-fit-cover{
    object-fit: cover;
}

.bottom-line-divider{
    border-bottom: solid thin #b7b7b7;
}

.m-5px{
    margin: 5px;
}

/****************************************************************/

.aviso-autor{
    background-color: #3b7db5;
    display: inline-block;
    color: #ffffff;
    padding: 3px 12px 3px 8px;
    border-radius: 15px;
    font-size: 9pt;
    img{
        width: 15px;
        height: 15px;
        margin-left: 4px;
        margin-right: 4px;
        object-fit: cover;
        border-radius: 50%;
        display: inline-block;
        background-color: #e7a61a;
    }
}


/****************************************************************/
/********************* Shopping Demo-4 **************************/
.product-grid4,.product-grid4 .product-image4{position:relative}
.product-grid4{font-family:Poppins,sans-serif;text-align:center;border-radius:5px;overflow:hidden;z-index:1;transition:all .3s ease 0s}
.product-grid4:hover{box-shadow:0 0 10px rgba(0,0,0,.2)}
.product-grid4 .product-image4 a{display:block}
// .product-grid4 .product-image4 img{width:100%;height:auto; max-height: 350px; object-fit: cover;}
.product-grid4 .product-image4 img{width:100%; height: 200px; object-fit: cover;}
.product-grid4 .pic-1{opacity:1;transition:all .5s ease-out 0s}
.product-grid4:hover .pic-1{opacity:0}
.product-grid4 .pic-2{position:absolute;top:0;left:0;opacity:0;transition:all .5s ease-out 0s}
.product-grid4:hover .pic-2{opacity:1}
.product-grid4 .social{width:180px;padding:0;margin:0 auto;list-style:none;position:absolute;right:0;left:0;top:50%;transform:translateY(-50%);transition:all .3s ease 0s}
.product-grid4 .social li{display:inline-block;opacity:0;transition:all .7s}
.product-grid4 .social li:nth-child(1){transition-delay:.15s}
.product-grid4 .social li:nth-child(2){transition-delay:.3s}
.product-grid4 .social li:nth-child(3){transition-delay:.45s}
.product-grid4:hover .social li{opacity:1}
.product-grid4 .social li a{color:#222;background:#fff;font-size:17px;line-height:36px;width:40px;height:36px;border-radius:2px;margin:0 5px;display:block;transition:all .3s ease 0s}
.product-grid4 .social li a:hover{color:#fff;background:#16a085}
.product-grid4 .social li a:after,.product-grid4 .social li a:before{content:attr(data-tip);color:#fff;background-color:#000;font-size:12px;line-height:20px;border-radius:3px;padding:0 5px;white-space:nowrap;opacity:0;transform:translateX(-50%);position:absolute;left:50%;top:-30px}
.product-grid4 .social li a:after{content:'';height:15px;width:15px;border-radius:0;transform:translateX(-50%) rotate(45deg);top:-22px;z-index:-1}
.product-grid4 .social li a:hover:after,.product-grid4 .social li a:hover:before{opacity:1}
.product-grid4 .product-discount-label,.product-grid4 .product-new-label{color:#fff;background-color:#16a085;font-size:13px;font-weight:800;text-transform:uppercase;line-height:45px;height:45px;width:45px;border-radius:50%;position:absolute;left:10px;top:15px;transition:all .3s}
.product-grid4 .product-discount-label{left:auto;right:10px;background-color:#d7292a}
.product-grid4:hover .product-new-label{opacity:0}
.product-grid4 .product-content{padding:25px}
.product-grid4 .title{font-size:15px;font-weight:400;text-transform:capitalize;margin:0 0 7px;transition:all .3s ease 0s}
.product-grid4 .title a{color:#222}
.product-grid4 .title a:hover{color:#16a085}
.product-grid4 .price{color:#16a085;font-size:17px;font-weight:700;margin:0 2px 15px 0;display:block}
.product-grid4 .price span{color:#909090;font-size:13px;font-weight:400;letter-spacing:0;text-decoration:line-through;text-align:left;vertical-align:middle;display:inline-block}
.product-grid4 .add-to-cart{border:1px solid #e5e5e5;display:inline-block;padding:10px 20px;color:#888;font-weight:600;font-size:14px;border-radius:4px;transition:all .3s}
.product-grid4:hover .add-to-cart{border:1px solid transparent;background:#16a085;color:#fff}
.product-grid4 .add-to-cart:hover{background-color:#505050;box-shadow:0 0 10px rgba(0,0,0,.5)}
@media only screen and (max-width:990px){.product-grid4{margin-bottom:30px}
}
/****************************************************************/

// // Para sidebar, cmabio de colores
// .sidebar {
//     display: flex;
//     flex-direction: column;
//     padding: 0;
//     color: white;
//     background: #3c4b64;

//     .nav-link {
//         &:hover {
//             background: rgba(0,0,21,.2);
//             color: #fff;
//         }
//     }

//     .nav-link.active {
//         background: rgba(0,0,21,.2);
//     }
// }