// Variable overrides

// f6d42fff

// $sidebarNavLinkSel: #3c3836;
// $sidebarNavLinkSelActive: #56514eff;

$sidebarBgColor: #303030ff;
$andamiosYellowColor: #303030ff;

// Variable overrides
$sidebarNavLinkSelText: #fff;
$sidebarNavLinkSelActiveText: #fff;
$sidebarNavLinkSel: #23282c;
$sidebarNavLinkSelActive: #1e71c5;

// $primary: #007bff !default;
// $secondary:     $gray-300 !default;
// $success:       $green !default;
// $info:          $light-blue !default;
// $warning:       $yellow !default;
// $danger:        $red !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;


$primary: #0397ef !default;
$secondary:    #d8dce0 !default;
// $secondary:     $gray-300 !default;
$success:       #007E33 !default;
$info:          #0099CC !default;
$warning:       #FF8800 !default;
$danger:        #CC0000 !default;
// $light:         $gray-100 !default;
// $dark:          $gray-800 !default;