// Here you can add other styles
// $bgColor: #303030ff;
// $dividerBgColor: #ffce00ff;
// $txtColor: #FFFFFF;

.app{
    
    header.app-header.navbar{

        .navbar-toggler{
            color: white;
            .navbar-toggler-icon{
                color: white !important;
            }
        }
    
    }
    
    #legacy-navbar.app-header{
        .navbar-brand.navsidebar-dynamic-bg{
            width: 210px;
            background-color: $sidebarBgColor;
        }
    }

    .sidebar {
        background: $sidebarBgColor;
        .sidebar-header{
            background: $sidebarBgColor;
        }
    }

    .sidebar .nav-dropdown .nav-dropdown-items .nav-link {
        color: #fff;
        border-left: 0;
        font-size: 85%;
        // padding-left: 10px !important;
        padding: 12px 16px 12px 25px;
    }
    
    .sidebar .nav-dropdown-toggle {
        position: relative;
        background: #2f353a;
    }
    
    .sidebar .nav-link:hover{
        color: $sidebarNavLinkSelText !important;
        background: $sidebarNavLinkSel;
    }
    
    .sidebar .nav-link.active{
        color: $sidebarNavLinkSelActiveText !important;
        font-weight: bold;
        background: $sidebarNavLinkSelActive;
    }
    
    .sidebar .nav-link.active .nav-icon {
        color: #fff;
    }

}


.card{
    // border-radius: 0px;
}

.card-header:not(.normal){
    background-color: white !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-primary:not(.normal){
    background-color: $primary !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-info:not(.normal){
    background-color: $info !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-secondary:not(.normal){
    background-color: $secondary !important;
    font-size: 14pt;
    font-weight: bold;
}

.card-header.bg-success:not(.normal){
    background-color: $success !important;
    font-size: 14pt;
    font-weight: bold;
}

.main .container-fluid {
    padding: 0 15px;
}

.font-80pc{
    font-size: 80%;
}
#modal{
    overflow-y: scroll !important;
}

.dropdown-item i{
    color: black;
}

.frame:before,
.frame_before {
    content: "";
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}

@include media-breakpoint-down(md) {
    .app{
        #legacy-navbar.app-header{
            .navbar-brand.navsidebar-dynamic-bg {
                background-color: transparent;
            }
        }
    }
}

@media (min-width: 992px){

    .brand-minimized .app-header .navbar-brand {
        width: 50px !important;
        background-color: transparent;
    }

    .sidebar-fixed .sidebar {
        width: 210px;
    }

    .sidebar .sidebar-nav {
        width: 210px;
    }
    
    .sidebar .nav {
        width: 210px;

        .nav-item:nth-child(3){
            padding-bottom: 20px;
            border-bottom: dashed thin #d6d6d6;
        }
        
        .nav-item:nth-child(4){
            padding-top: 20px;
        }

    }

    .sidebar .sidebar-header {
        padding: 0.5rem 1rem 0.3rem 1rem;
    }

    html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-lg-show.sidebar-fixed .app-footer, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .main, html:not([dir="rtl"]) .sidebar-show.sidebar-fixed .app-footer {
        margin-left: 210px;
    }

    html:not([dir="rtl"]) .sidebar {
        margin-left: -210px;
    }
    

}