/* Euphemia UCAS */
@font-face{
    font-family: 'Euphemia UCAS';
    src: local('Euphemia UCAS'), url('../fonts/EuphemiaCAS.ttc') format('truetype');
}

/* Couture Bold */
@font-face{
    font-family: 'Couture Bold';
    src: local('Couture Bold'), url('../fonts/couture-bld.otf') format('opentype');
}

/* HunDIN1451 */
@font-face{
    font-family: 'HunDIN1451';
    src: local('HunDIN1451'), url('../fonts/HunDIN1451.ttf') format('truetype');
}
