// Styles
// CoreUI Icons Set
@import '~@coreui/icons/css/coreui-icons.css';
// Import Flag Icons Set
@import '~flag-icon-css/css/flag-icon.min.css';
// Import Font Awesome Icons Set
@import '~font-awesome/css/font-awesome.min.css';
// Import Simple Line Icons Set
@import '~simple-line-icons/css/simple-line-icons.css';
// Import Main styles for this application
@import './scss/style.scss';

// https://fonts.google.com/specimen/Open+Sans?sidebar.open=true&selection.family=Open+Sans:wght@300;400;600
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600&display=swap');

body{
    font-family: 'Open Sans', sans-serif;
}

input[type="text"], input[type="number"], input[type="email"],
input[type="password"],textarea, select{
    color: #333333;
    &:focus, &:active, &:hover{
        color: #111111;
    }
}

textarea, select{
    color: #333333 !important;
}

// para el dropzone

.dropzone {
    // flex: 1;
    // display: flex;
    // flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #555050;
    border-style: dashed;
    background-color: #fafafa;
    color: #555050;
    outline: none;
    transition: border .24s ease-in-out;
}

.dropzone:focus {
    border-color: #2196f3;
}

.dropzone.disabled {
    opacity: 0.6;
}

// para autocomplete

.menu {
    position: absolute;
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #cccccc;
}

.item {
    padding: 2px 6px;
    cursor: default;
}

.item-highlighted {
    color: white;
    background-color: #4095bf;
}

.item-header {
    background-color: #eeeeee;
    color: #454545;
    font-weight: bold;
}

.sidebar{
    // z-index: 150 !important;
}

// para datepicker zindex
.react-datepicker-popper2 {
    z-index: 99999 !important;
}

// ._loading_overlay_wrapper, ._loading_overlay_wrapper--active, .css-79elbk{
//     z-index: 1051 !important;
// }


.text-sm{
    font-size: 95%;
}

//  para TablePaginate
.table-paginate-filter-on{
    border: solid 2px $primary;
    color: $success !important;
    font-weight: bold;
}

.bottom-orange-ae{
    box-shadow: 0px 2px 5px orange;
    background-color: #fafcfd;
}

.custom-dropdown-menu{
    border-radius: 2px;
}


table.table-equipos-rentados{
    border: solid thin #c8ced3;
}

.btn-back{
    border-radius: 50%;
    vertical-align: top !important;
}

.my-btn{
    border-radius: 15px;
    i{
        padding-right: 10px;
    }
}

.table .thead-light th {
    // color: #5c6873;
    color: #373e45;
    font-size: 110%;
    background-color: #e4e7ea;
    border-color: #c8ced3;
}

.row-softorange{
    background-color: #fff4d5;
    // background-color: #fff8e3;
}

::-webkit-scrollbar {
    width: 10px;
}

::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2);
}

::-webkit-scrollbar-thumb {
    box-shadow: inset 2px 3px 10px rgba(55, 62, 69, 1);
}

html {
    scrollbar-color: rgba(55, 62, 69, 1) rgba(0, 0, 0, 0.2);
    scrollbar-width: thin;
}