@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@700&display=swap');

.app-body{
    background-color: #f6f6ff;

    .main{
        padding-top: 24px;
    }
}

.app-header{
    border-bottom: none;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
                0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
                0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
                0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.container-breadcrumb .breadcrumb{
    border: none;
    border-radius: 5px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

.card:not(.normal){
    border: none;
    border-radius: 5px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);

    .card-header:not(.normal){
        border-bottom-color: #dee1e4;
        font-family: "Heebo",sans-serif;
        font-weight: 700;
    }

}

.btn-dashboard-item{
    padding    : 20px;
    background-color : #E2EDFC;
    font-weight: bold;
    color: #3c3d3f;
    min-height: 150px;
    
    .label{
        padding-top: 8px;
        font-size  : 14pt;
    }

    &:hover, &:active, &:focus{
        background-color : #cbd5e2;
    }

}

.bg-soft-gray-blue{
    background-color: #E2EDFC;
}

.font-title-heebo{
    color: #43474b;
    font-family: "Heebo",sans-serif;
    font-size: 14pt;
}

.font-title-heebo-12pt{
    color: #43474b;
    font-family: "Heebo",sans-serif;
    font-size: 12pt;
}

.font-title-heebo-10pt{
    color: #43474b;
    font-family: "Heebo",sans-serif;
    font-size: 10pt;
}

.font-subtitle-heebo{
    margin-left: 10px;
    color: gray;
    font-size: 11pt;
}

.not-bordered, .border-none{
    border: none
}

// TABLES

.table-hover.table-hover-soft-orange tbody tr:hover td, .table-hover.table-hover-soft-orange tbody tr:hover th{
    background-color: #fff8e5;      
}

.table .thead-light-blue th{
    background-color: rgba(65,145,255,.15) !important;
    text-transform: uppercase;
    font-size: 11pt !important;
    font-weight: 700 !important;
}

.table-equipos-rentados{
    border-radius: 5px;
    box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
}

// IMAGES

.andamios-img-thumb{

    display      : inline-flex;
    border-radius: 2px;
    border       : 2px solid #eaeaea;
    margin-bottom: 8px;
    margin-right : 8px;
    width        : 120px;
    height       : 120px;
    padding      : 4px;
    box-sizing   : border-box;


    .andamios-thumb-inner{

        display  : flex;
        min-width: 0px;
        overflow : hidden;

        img{
            display: block;
            width  : auto;
            height : 100%;
            cursor: pointer;
        }

        .andamios-inner{
            position    : absolute;
            top         : 10px;
            right       : 25px;
            margin-top  : 2px;
            margin-right: 2px;
        }
    }
}



// ESTILOS PARA RC-STEPS

.rc-steps-navigation{
    padding-top: 0px !important;
}

.rc-steps-navigation .rc-steps-item-title {
    font-size: 16px;
}

.rc-steps-item-container{
    display: block !important;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    
}

.rc-steps-navigation .rc-steps-item-title{
    max-width: 200px !important;
}

.rc-steps-item-finish .rc-steps-item-icon > .rc-steps-icon{
    color: white !important;
}

.rc-steps-item-finish .rc-steps-item-icon {
    border-color: $success !important;
    background-color: $success !important;
}

.rc-steps-item-finish .rc-steps-item-title {
    color: $success !important;
}

.mi-box-shadow-hover{
    &:hover{
        box-shadow: 0 0.46875rem 2.1875rem rgba(59, 62, 102, 0.03), 
                    0 0.9375rem 1.40625rem rgba(59, 62, 102, 0.03), 
                    0 0.25rem 0.53125rem rgba(59, 62, 102, 0.05), 
                    0 0.125rem 0.1875rem rgba(59, 62, 102, 0.03);
    }
}